<template>
  <div class="home">
		<div class="top">
			<img class="logo" src="../assets/logo.svg">
		</div>
		<div class="center">
			<img class="bg" src="../assets/bg.jpeg">
			<img class="bg2" src="../assets/bg2.jpeg">
		</div>
		<div class="bottom">
			<p>
			版权所属：高仕利高尔夫用品贸易（深圳）有限公司
			<br>
			<a href="https://beian.miit.gov.cn">备案号：粤ICP备 2022083033号-1</a ></p>
		</div>
  </div>
</template>

<script>


export default {
  name: 'Home'
}
</script>
<style>
	body,html{
		margin: 0;
		padding: 0;
	}
	body{
		overflow: hidden;
	}
	.top{
		background: #fff;
		height: 7vh;
		width: 100%;
		text-align: left;
	}
	.logo{
		width: 150px;
		height: 100%;
		object-fit: contain;
		margin-left: 20px;
	}
	.center{
		width: 100%;
	}
	.bg,.bg2{
		width: 100%;
		height: 93vh;
		object-fit: cover;
		object-position: center center;
		display: block;
	}
	.bg2{
		display: none;
	}
	.bottom{
		position: absolute;
		bottom: 0;
		width: 100%;
		height: 7vh;
		text-align: center;
		background-color: rgba(0,0,0,.6);
		
	}
	.bottom p{
		color: #fff;
		box-sizing: border-box;
		padding-top: 20px;
		margin: 0;
	}
	.bottom p a{
		color: #fff;
		text-decoration: 0;
	}
	@media (max-width: 799px) {
		.top{
			text-align: center;
		}
		.logo{
			width: 100px;
			padding: 0;
			margin: 0;
		}
		.bg{
			display: none;
		}
		.bg2{
			display: block;
			/* object-fit: contain;
			object-position: top center; */
		}
	}
</style>
